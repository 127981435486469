<template>
  <section>
    <div class="ml-4">
      <p class="text-h6 text--secondary mb-0">{{ correlativo_proceso }}</p>
      <p class="text-h6 text--secondary mb-0">Seguimiento de contratos</p>
    </div>

    <proveedores :prevRoute="prevRoute" :headers="encabezadosGanadores" />
  </section>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import proveedores from "../../components/ProveedorComponent.vue";
export default {
  name: "seguimientoContratos",
  components: {
    proveedores,
  },
  data: () => ({
    encabezadosGanadores: [
      {
        text: "Proveedor",
        value: "proveedor_nombre",
        width: "80%",
      },
      {
        text: "Acciones",
        align: "center",
        value: "accionesGanadoresPAAC",
        sortable: false,
      },
    ],
    prevRoute: null,
  }),
  computed: {
    ...mapState("agregarInsumo", ["id_solicitud", "proveedoresList"]),
    ...mapState("procesoCompra", ["correlativo_proceso"]),
  },
  methods: {
    ...mapMutations("agregarInsumo", ["setProveedoresList"]),
    async getProvidersGanadores() {
      const { status, data } =
        await this.services.ContratoService.getContratoProcesosGanadores(
          this.$route.params.id_proceso
        );

      if (status == 200) {
        this.setProveedoresList(data);
      }
    },
  },
  created() {
    this.getProvidersGanadores();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.name;
    });
  },
};
</script>
<style>
.theme--light.v-application .text--secondary {
  color: #404a7a !important;
}
</style>
